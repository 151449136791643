
import { defineComponent, computed, onMounted } from 'vue'
import { MqResponsive } from 'vue3-mq'

import AsHeaderMobile from '@web/components/headers/AsHeaderMobile.vue'
import AsHeaderMain from '@web/components/headers/AsHeaderMain.vue'
import AsMenu from '@web/components/AsMenu.vue'
import AsSpacer from '@web/components/AsSpacer.vue'
import AsFooter from '@web/components/AsFooter.vue'
import AsBreadcrumb, { crumb } from '@web/components/AsBreadcrumb.vue'
import AsSubscription from '@web/components/AsSubscription.vue'
import AsBtnCancelSubscription from '@web/components/AsBtnCancelSubscription.vue'
import AsRow from '@web/components/AsRow.vue'
import i18n from '@web/plugins/i18n'
import { useStore } from '@web/store'
import { useMeta } from '@web/common/vueMeta'
import Swal from 'sweetalert2'
import sentry from '@web/common/sentry'
import Logger from '@web/common/Logger'
import { useRoute } from 'vue-router'
import { Subscription } from '@web/types/Subscription'

export default defineComponent({
  name: 'VSubscription',
  components: {
    MqResponsive,
    AsHeaderMobile,
    AsHeaderMain,
    AsMenu,
    AsSpacer,
    AsFooter,
    AsBreadcrumb,
    AsSubscription,
    AsBtnCancelSubscription,
    AsRow
  },
  setup () {
    const store = useStore()
    const route = useRoute()

    const title = i18n.global.t('Subscription')
    const menu = computed(() => store.getters['menu/general'])
    useMeta({
      title
    })

    const subscriptionId = Number(route.params.id)
    const subscription = computed<Subscription | undefined>(() => store.getters['subscription/subscription'](subscriptionId))

    const breadcrumbs: crumb[] = [
      {
        name: 'courses',
        title: i18n.global.t('crumb.general')
      },
      {
        name: 'subscriptions',
        title: i18n.global.t('crumb.subscriptions')
      },
      {
        name: 'subscription',
        title: i18n.global.t('crumb.subscription'),
        params: {
          id: route.params.id
        }
      }
    ]

    onMounted(async () => {
      try {
        await store.dispatch('subscription/fetchSubscription', subscriptionId)
      } catch (e) {
        Swal.fire({
          title: i18n.global.t('error.error'),
          text: i18n.global.t('error.subscriptions'),
          icon: 'error'
        })
        sentry.captureException(e)
        Logger.error(e)
      }
    })

    return {
      title,
      menu,
      subscription,
      breadcrumbs
    }
  }
})
