import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17049fe8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "period" }
const _hoisted_5 = { class: "endDate" }
const _hoisted_6 = {
  key: 1,
  class: "arrow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["asSubscription", { '-link': _ctx.link }])
  }, [
    (_ctx.subscription.preview)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "preview",
          src: _ctx.subscription.preview,
          alt: ""
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.subscription.title), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.subscription.period), 1),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.subscription.endDate), 1)
    ]),
    (_ctx.link)
      ? (_openBlock(), _createElementBlock("span", _hoisted_6))
      : _createCommentVNode("", true)
  ], 2))
}