import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50bfaac7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lPage" }
const _hoisted_2 = {
  key: 0,
  class: "subscription"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_header_mobile = _resolveComponent("as-header-mobile")!
  const _component_as_subscription = _resolveComponent("as-subscription")!
  const _component_as_btn_cancel_subscription = _resolveComponent("as-btn-cancel-subscription")!
  const _component_mq_responsive = _resolveComponent("mq-responsive")!
  const _component_as_breadcrumb = _resolveComponent("as-breadcrumb")!
  const _component_as_header_main = _resolveComponent("as-header-main")!
  const _component_as_menu = _resolveComponent("as-menu")!
  const _component_as_row = _resolveComponent("as-row")!
  const _component_as_spacer = _resolveComponent("as-spacer")!
  const _component_as_footer = _resolveComponent("as-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_mq_responsive, { target: "mobile-tablet" }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_mobile, {
          prevPage: { name: 'subscriptions' },
          nav: false,
          title: _ctx.title
        }, null, 8, ["title"]),
        (_ctx.subscription)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_as_subscription, { subscription: _ctx.subscription }, null, 8, ["subscription"]),
              _createVNode(_component_as_btn_cancel_subscription, {
                id: _ctx.subscription.id,
                "cancel-popup-text": _ctx.subscription.cancelPopupText
              }, null, 8, ["id", "cancel-popup-text"])
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_mq_responsive, {
      class: "lAuth -edit bg-blue",
      target: "desktop"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_main, null, {
          breadcrumb: _withCtx(() => [
            _createVNode(_component_as_breadcrumb, { breadcrumbs: _ctx.breadcrumbs }, null, 8, ["breadcrumbs"])
          ]),
          _: 1
        }),
        _createVNode(_component_as_row, null, {
          menu: _withCtx(() => [
            _createVNode(_component_as_menu, {
              menu: _ctx.menu,
              type: "sidebar"
            }, null, 8, ["menu"])
          ]),
          content: _withCtx(() => [
            (_ctx.subscription)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_as_subscription, { subscription: _ctx.subscription }, null, 8, ["subscription"]),
                  _createVNode(_component_as_btn_cancel_subscription, {
                    id: _ctx.subscription.id,
                    "cancel-popup-text": _ctx.subscription.cancelPopupText
                  }, null, 8, ["id", "cancel-popup-text"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_as_spacer),
        _createVNode(_component_as_footer)
      ]),
      _: 1
    })
  ]))
}