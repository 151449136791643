
import { computed, defineComponent, PropType, ref } from 'vue'
import i18n from '@web/plugins/i18n'
import { useStore } from '@web/store'
import Logger from '@web/common/Logger'
import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'

import AsModal from '@web/components/AsModal.vue'
import AcSelect from '@ui-kit/components/AcSelect.vue'
import AsTextarea from '@web/components/AsTextarea.vue'
import AsButton from '@web/components/AsButton.vue'

export default defineComponent({
  props: {
    id: {
      type: Number as PropType<number>,
      required: true
    },
    cancelPopupText: {
      type: String,
      default: i18n.global.t('cancelSubscribeBtn.defaultText')
    }
  },
  components: {
    AsModal,
    AcSelect,
    AsTextarea,
    AsButton
  },
  setup (props) {
    const store = useStore()
    const router = useRouter()
    const reasons = [
      {
        label: i18n.global.t('cancelSubscribeBtn.notSatisfiedWithContent'),
        value: 0
      },
      {
        label: i18n.global.t('cancelSubscribeBtn.inconvenientUsePlatform'),
        value: 1
      },
      {
        label: i18n.global.t('cancelSubscribeBtn.notEnoughTime'),
        value: 2
      },
      {
        label: i18n.global.t('cancelSubscribeBtn.notSatisfiedSubscriptionPrice'),
        value: 3
      },
      {
        label: i18n.global.t('cancelSubscribeBtn.wantTakeBreak'),
        value: 4
      },
      {
        label: i18n.global.t('cancelSubscribeBtn.technicalProblems'),
        value: 5
      },
      {
        label: i18n.global.t('cancelSubscribeBtn.other'),
        value: 6
      }
    ]
    const reason = ref(0)
    const reasonText = ref('')
    const reasonTextError = ref('')
    const isOpenModal = ref(false)
    const reasonTextNote = computed(() => reason.value === 6 ? `${i18n.global.t('cancelSubscribeBtn.minSymbols', { number: 80 })}. ${i18n.global.t('cancelSubscribeBtn.countSymbols', { count: reasonText.value.length })}` : '')

    enum statuses {
      ready = 'ready',
      wait = 'wait',
      fail = 'fail',
      success = 'success'
    }
    const status = ref<statuses>(statuses.ready)
    const text = computed(() => i18n.global.t(`cancelSubscribeBtn.${status.value}`))

    function onChangeReason (reasonId) {
      reason.value = Number(reasonId)
    }

    function chooseReason () {
      isOpenModal.value = true
    }

    function notCancelSubscription () {
      isOpenModal.value = false
    }

    async function cancelSubscription () {
      if (reason.value === 6 && reasonText.value.length < 80) {
        reasonTextError.value = i18n.global.t('cancelSubscribeBtn.minSymbols', { number: 80 })
        return
      }
      try {
        status.value = statuses.wait
        await store.dispatch('subscription/cancelSubscription', {
          id: props.id,
          reasonType: reasons.find(r => r.value === reason.value)?.label || 'Unknown',
          reason: reasonText.value
        })
        status.value = statuses.success
        isOpenModal.value = false
      } catch (e) {
        status.value = statuses.fail
        Logger.error(e)
      }
    }
    async function handlerCancelSubscription () {
      const title = status.value !== statuses.fail ? i18n.global.t('cancelSubscribeBtn.areYouSureCancelSubscribe') : i18n.global.t('notFound.text')
      const text = status.value !== statuses.fail ? props.cancelPopupText || i18n.global.t('cancelSubscribeBtn.defaultText') : ''
      const result = await Swal.fire({
        title,
        text,
        confirmButtonText: i18n.global.t('yes'),
        showCancelButton: true,
        cancelButtonText: i18n.global.t('no'),
        showDenyButton: status.value !== statuses.fail,
        denyButtonText: i18n.global.t('contactSupport')
      })

      if (result.isDismissed) {
        return
      }
      if (result.isDenied) {
        return router.push({ name: 'support' })
      }
      if (status.value !== statuses.ready) {
        return
      }

      chooseReason()
    }

    return {
      text,
      status,
      reasons,
      reason,
      reasonText,
      reasonTextError,
      isOpenModal,
      reasonTextNote,
      notCancelSubscription,
      onChangeReason,
      cancelSubscription,
      handlerCancelSubscription
    }
  }
})
