import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "asModalCancelSubscription" }
const _hoisted_2 = { class: "asModalCancelSubscription_actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ac_select = _resolveComponent("ac-select")!
  const _component_as_textarea = _resolveComponent("as-textarea")!
  const _component_as_button = _resolveComponent("as-button")!
  const _component_as_modal = _resolveComponent("as-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["asBtnCancelSubscription", `-${_ctx.status}`]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handlerCancelSubscription && _ctx.handlerCancelSubscription(...args)))
    }, _toDisplayString(_ctx.text), 3),
    _createVNode(_component_as_modal, {
      modelValue: _ctx.isOpenModal,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isOpenModal) = $event)),
      "btn-close": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ac_select, {
            "model-value": _ctx.reason,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChangeReason($event))),
            options: _ctx.reasons,
            label: _ctx.$t('chooseReason'),
            class: "asModalCancelSubscription_select"
          }, null, 8, ["model-value", "options", "label"]),
          _createVNode(_component_as_textarea, {
            class: "asModalCancelSubscription_textarea -grey",
            label: _ctx.$t('describeMoreDetail'),
            name: "reason-text",
            modelValue: _ctx.reasonText,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.reasonText) = $event)),
            modelModifiers: { trim: true },
            note: _ctx.reasonTextNote,
            error: _ctx.reasonTextError,
            "auto-resizable": ""
          }, null, 8, ["label", "modelValue", "note", "error"]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_as_button, {
              class: "asModalCancelSubscription_btn",
              onClick: _ctx.cancelSubscription
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('cancelSubscribeBtn.ready')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_as_button, {
              class: "asModalCancelSubscription_btn -cancel",
              onClick: _ctx.notCancelSubscription
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('no')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}