
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    subscription: {
      type: Object as PropType<{
        preview?: string;
        title: string;
        period: string;
        endDate?: string;
      }>,
      required: true
    },
    link: {
      type: Boolean,
      default: false
    }
  }
})
